import apiProvider from "@/utils/api";
import axios, { AxiosPromise, AxiosResponse } from "axios";
import { toUrl } from "@/utils/helpers";
import User from "@/models/User";
import store from "@/store";
const rootPath = "Users";

class UserService {
  public getTableUrl(userProfil: string, lang: string): string {
    return `${apiProvider.getUrl()}${rootPath}${toUrl({
      lang: lang,
      userProfil: userProfil,
    })}`;
  }

  public forgotPassword(mail: string, lang: string): AxiosPromise {
    return apiProvider.post(`${rootPath}/forgotPassword`, {
      mail: mail,
      lang: lang,
    });
  }

  public resetPassword(data: object): AxiosPromise {
    return apiProvider.post(`${rootPath}/ResetPassword`, data);
  }

  public getUserShort(): string {
    var savedUser = localStorage.getItem("user");
    if (!savedUser) {
      return "";
    }
    let user = JSON.parse(savedUser);
    var result = user.firstName.charAt(0) + "." + user.lastName;
    return result.toString();
  }

  public getusersByRole(userRole: string, lang: string): AxiosPromise {
    return apiProvider.get(
      `${apiProvider.getUrl()}${rootPath}${toUrl({ lang, userRole })}`
    );
  }

  public getContactUsersByEntreprise(
    entrepriseId: string,
    lang: string
  ): AxiosPromise {
    return apiProvider.get(
      `${apiProvider.getUrl()}${rootPath}/entrepriseUsers${toUrl({
        lang,
        entrepriseId,
        isContact: true,
      })}`
    );
  }
  public getOrderGiverUsersBySite(siteId: string, lang: string): AxiosPromise {
    return apiProvider.get(
      `${apiProvider.getUrl()}${rootPath}/orderGiverUsers${toUrl({
        lang,
        siteId,
      })}`
    );
  }
  public getCurrentuser(): User {
    var curretnUser: User = store.state.userModule.user; // localStorage.getItem('user');
    if (curretnUser === null) curretnUser = new User();

    return curretnUser;
  }
  public addUser(data: any, lang: string): AxiosPromise {
    return apiProvider.put(
      `${rootPath}/${data.id}${toUrl({ lang: lang })}`,
      data
    );
  }

  public saveUsers(data: Array<any>, lang: string): AxiosPromise {
    return apiProvider.post(`${rootPath}${toUrl({ lang: lang })}`, data);
  }

  public loginStore(data: any) {
    return apiProvider.post<User>(`${rootPath}/authenticate`, data);
  }

  public getConnectedUser(bearer: any): AxiosPromise {
    var AuthStr = "Bearer " + bearer;
    return apiProvider.get(`${rootPath}/getConnectedUser`, {
      headers: {
        Authorization: AuthStr,
        "Content-Type": "application/json",
      },
      disable401Verification: true,
    });
  }

  public login(username: string, password: string) {
    const data = {
      Login: username,
      Password: password,
    };

    return apiProvider
      .post<User>(`${rootPath}/authenticate`, data)
      .then(this.handleResponse)
      .catch((error) => {
        return Promise.reject(error);
      })
      .then((user: User) => {
        // login successful if there's a user in the response
        if (user) {
          // store user details and basic auth credentials in local storage
          // to keep user logged in between page refreshes
          // user.authdata = window.btoa(username + ':' + password);
          localStorage.setItem("user", JSON.stringify(user));
        }

        return user;
      });
  }

  public refreshCurrentUser() {
    return apiProvider.get(
      `${apiProvider.getUrl()}${rootPath}/getCurrentUser`,
      { disable401Verification: true }
    );
  }

  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem("user");
  }

  handleResponse(response: AxiosResponse<User>) {
    if (response.status === 401) {
      // auto logout if 401 response returned from api
      this.logout();
      location.reload();
      const error = response.statusText;
      return Promise.reject(error);
    }

    return response.data;
  }
}
export default new UserService();
