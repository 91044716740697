import UserService from "@/services/UserService";
import VueRouter, { RouteConfig } from "vue-router";
import axios from "axios";
import authHeader from "@/utils/auth-header";

import Login from "@/components/Auth/Login.vue";
import ResetPassword from "@/views/ResetPassword.vue";
import Accueil from "@/views/Accueil.vue";
import Bop from "@/views/Bop.vue";
import Auth from "@/views/Auth.vue";
import Sites from "@/views/Sites.vue";
import Entreprises from "@/views/Entreprises.vue";
import Documentation from "@/views/Documentation.vue";
import ArkemaUsers from "@/views/ArkemaUsers.vue";
import ExternalUsers from "@/views/ExternalUsers.vue";
import Facturation from "@/views/Facturation.vue";
import Translations from "@/views/Translations.vue";
import BopModelsSiteConfiguration from "@/views/BopModelsSiteConfiguration.vue";
import BopModelSetup from "@/views/BopModelSetup.vue";
import BopSetup from "@/views/BopSetup.vue";
import Unauthorized from "@/views/Unauthorized.vue";
import SsoCallBack from "@/views/SsoCallBack.vue";
import i18n, { loadLanguageAsync } from "@/i18n";
import store from "@/store";

import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import ModalService from "@/services/ModalService";
import BootstrapVue from "bootstrap-vue";
import BopService from "@/services/BopService";
Vue.use(VueRouter);
Vue.use(BootstrapVue);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "Accueil",
    component: Accueil,
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/resetPassword",
    name: "ResetPassword",
    component: ResetPassword,
  },
  {
    path: "/Auth",
    name: "Auth",
    component: Auth,
  },
  {
    path: "/Bop",
    name: "Bop",
    component: Bop,
  },
  {
    path: "/Sites",
    name: "Sites",
    component: Sites,
    meta: {
      isAdminRoute: true,
    },
  },
  {
    path: "/Entreprises",
    name: "Entreprises",
    component: Entreprises,
    meta: {
      isAdminRoute: true,
    },
  },
  {
    path: "/Documentation",
    name: "Documentation",
    component: Documentation,
  },
  {
    path: "/ArkemaUsers",
    name: "ArkemaUsers",
    component: ArkemaUsers,
    meta: {
      isAdminRoute: true,
    },
  },
  {
    path: "/ExternalUsers",
    name: "ExternalUsers",
    component: ExternalUsers,
    meta: {
      isAdminRoute: true,
    },
  },
  {
    path: "/Facturation",
    name: "Facturation",
    component: Facturation,
    meta: {
      isAdminRoute: true,
    },
  },
  {
    path: "/Translations",
    name: "Translations",
    component: Translations,
    meta: {
      isAdminRoute: true,
    },
  },
  {
    path: "/bopmodelsSitesConfiguration",
    name: "BopModelsSiteConfiguration",
    component: BopModelsSiteConfiguration,
    meta: {
      isAdminRoute: true,
    },
  },
  {
    path: "/bopmodels/configuration",
    name: "BopModelSetup",
    component: BopModelSetup,
    meta: {
      isAdminRoute: true,
    },
  },
  {
    path: "/bops/add",
    name: "BopSetupAdd",
    component: BopSetup,
  },
  {
    path: "/bops/:bopId",
    name: "BopSetupUpdate",
    component: BopSetup,
    meta: {
      customAcessDefiner: async (to: any) => {
        const bopId = to.params.bopId;
        return (await BopService.userHaveAccess(bopId)).data.canAccessBop;
      },
    },
  },
  {
    path: "/callback",
    name: "SsoCallBack",
    component: SsoCallBack,
  },
  {
    path: "/unauthorized",
    name: "Unauthorized",
    component: Unauthorized,
  },
  // otherwise redirect to home
  { path: "*", redirect: "/" },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.VUE_APP_BASE_PATH,
  routes,
});
const clearNotifications = function() {
  router.app.$notify({
    group: "globalError",
    clean: true,
  });
};

router.beforeEach(async (to: any, from: any, next: any) => {
  const lang = i18n.locale == "undefined" ? "fr" : i18n.locale;
  const isRefresh: boolean = !from.name;
  router.app.$store.commit("changeInRouter", true);

  if (!store.state.hasBeenModified) {
    // router.app.$store.commit("changeIsTranslationLoaded", false);
  }

  // first thing first, load the translations
  const response = await loadLanguageAsync(lang);
  if (response) {
    const publicPages = ["/Auth", "/ResetPassword", "/callback"];
    const authRequired = !publicPages.includes(to.path);

    const loggedIn = store.getters.isLoggedIn
      ? store.getters.user.bearerToken || (await store.dispatch("refreshUser"))
      : false;
    clearNotifications();
    router.app.$store.commit("changeIsTranslationLoaded", true);

    // redirect to login page if not logged in and trying to access a restricted page
    if (authRequired && !loggedIn) {
      next({
        path: "/Auth",
        query: { returnUrl: to.path },
      });
    } else if (!authRequired && loggedIn) {
      next({
        path: "/accueil",
      });
    } else if (loggedIn && to.path === "/Auth") {
      next({
        path: "/accueil",
      });
    }

    let toPath = {};
    //checking if its and administration page and the user have the right privileges to access it
    if (to.meta && to.meta.isAdminRoute) {
      const adminRoles = [
        "centralAdministrator",
        "countryAdministrator",
        "siteAdministrator",
      ];
      const isCurrentUserAdmin = adminRoles.includes(store.getters.userRoleKey);
      toPath = !isCurrentUserAdmin ? { path: "/accueil" } : {};
    } // check using a custom access definer if the user have the right to access this route with a specific rule, the
    else if (to.meta && to.meta.customAcessDefiner) {
      const haveAccess = await to.meta.customAcessDefiner(to);
      if (!haveAccess) toPath = { path: "/unauthorized" };
    }

    //To prevent warning when saving a bop and changing its step index, since all the bop is saved at once
    var isBopIndexChange = to.name == "BopSetupUpdate" && to.path == from.path;

    //Warn user when changing page without saving
    if (!isRefresh && store.state.hasBeenModified && !isBopIndexChange) {
      if (router.app.$bvModal) {
        const value = await router.app.$bvModal.msgBoxConfirm(
          router.app.$t("modal.unsaved.message").toString(),
          {
            title: router.app.$t("modal.unsaved.title").toString(),
            size: "lg",
            buttonSize: "lg",
            okVariant: "primary",
            cancelVariant: "secondary",
            okTitle: router.app.$t("modal.unsaved.yes").toString(),
            cancelTitle: router.app.$t("modal.unsaved.no").toString(),
            dialogClass: "modal-app",
            footerClass: "p-2",
            hideHeaderClose: false,
            centered: true,
          }
        );
        if (value) {
          store.commit("changeHasBeenModified", false);
          router.app.$store.commit("changeInRouter", false);
          next(toPath);
        }
      } else {
        // router.app.$store.commit("changeHasBeenModified", false);
        router.app.$store.commit("changeInRouter", false);

        next(toPath);
      }
    } else {
      router.app.$store.commit("changeInRouter", false);

      next(toPath);
    }
  }
});
export default router;
