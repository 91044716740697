






































































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import BopModelService from "@/services/BopModelService";
import TypeTravauxService from "@/services/TypeTravauxService";
import CoefficientService from "@/services/CoefficientService";
import {
  BopModelEnum,
  CoefficientTypeEnum,
  RubriqueEnum,
  UserRoleEnum,
} from "@/utils/Enums";
import ImportBopModelModal from "@/components/BopModelSetup/ImportBopModelModal.vue";
import ExportBopModelHelper from "@/utils/ExportHelper";

type Data = {
  columns: Array<Object>;
  rows: Array<Object>;
};
const SEPARATOR_KEY = "separator";
@Component({
  components: {
    ImportBopModelModal,
    Table: () => import("@/components/Shared/Table/SimpleTable.vue"),
    OperationsSetup: () =>
      import("@/components/BopModelSetup/OperationsSetup.vue"),
    CoefficientDimensionsSetup: () =>
      import("@/components/BopModelSetup/CoefficientDimensionsSetup.vue"),
    CoefficientPerSiteSetup: () =>
      import("@/components/BopModelSetup/CoefficientPerSiteSetup.vue"),
    ExceptionSetup: () =>
      import("@/components/BopModelSetup/ExceptionSetup.vue"),
    ReferenceFournitures: () =>
      import("@/components/BopModelSetup/ReferenceFournitures.vue"),
    ComplementsCoutsSuppAdministration: () =>
      import(
        "@/components/BopModelSetup/ComplementsCoutsSuppAdministration.vue"
      ),
  },
})
export default class BopModelSetup extends Vue {
  public bopModels: Array<Object> = [];
  public selectedRows: Array<string> = [];
  public checkedRows: Array<string> = [];
  public selectedBopModel: any = {};
  public selectedRubrique: any = "";
  public selectedCoefficientId: string = "";
  public selectedTypeTravaux: Array<string> = [];
  public tableTypeTravaux: Array<string> = [];
  public oldTypeTravaux: Array<string> = [];
  private typeTravaux: Array<Object> = [];
  private rubriqueOptions: Array<any> = [];
  private coefficientTypes: Array<any> = [];
  private language = this.$i18n.locale;
  private lastSelectedBopModelId: string = "";
  private tableBopModel: any = "";
  private oldBopModel: any = "";
  private tableRubrique: any = "";
  private oldRubrique: any = "";
  private tableCoefficientId: any = "";
  private oldCoefficientId: any = "";
  private coefficientMetadata: any = null;
  private get canShowExportLoader(): boolean {
    return this.$store.state.showExportLoader;
  }
  public get isCentralAdministrator(): boolean {
    return (
      this.$store.getters.userRoleKey === UserRoleEnum.centralAdministrator
    );
  }
  private set canShowExportLoader(value: boolean) {
    this.$store.commit("changeShowExportLoader", value);
  }
  private get rubriques() {
    return RubriqueEnum;
  }

  private exportBopModelUrl() {
    if (!this.selectedBopModel) return;
    this.canShowExportLoader = true;
    ExportBopModelHelper.handleExport(this.selectedBopModel.key, this.language)
      .then(() => {
        this.canShowExportLoader = false;
      })
      .catch((er) => {
        console.error(er);
        this.canShowExportLoader = false;
      });
  }

  private get isType5Coefficient(): boolean {
    return (
      this.coefficientMetadata &&
      this.coefficientMetadata.coefficientType === CoefficientTypeEnum.T5
    );
  }

  private get isNotType5Coefficient(): boolean {
    return (
      this.coefficientMetadata &&
      this.coefficientMetadata.coefficientType !== CoefficientTypeEnum.T5
    );
  }

  public async handleChange() {
    if (
      this.selectedBopModel &&
      this.selectedRubrique == RubriqueEnum.CoefficientDimensions &&
      this.selectedBopModel.id != this.lastSelectedBopModelId
    ) {
      this.lastSelectedBopModelId = this.selectedBopModel.id;
      this.coefficientTypes = (
        await CoefficientService.getConfigurableCoefficientTypes(
          this.selectedBopModel.id,
          this.$i18n.locale
        )
      ).data;
    }
  }

  public created() {
    this.loadDropDowns();
  }

  private handleImport() {
    (<ImportBopModelModal>this.$refs.importBopModelModal).openImportModal();
  }

  private handleRubriqueChange(value: any) {
    if (this.$store.state.hasBeenModified) {
      this.$bvModal
        .msgBoxConfirm(this.$t("modal.unsaved.message").toString(), {
          title: this.$t("modal.unsaved.title").toString(),
          size: "lg",
          buttonSize: "lg",
          okVariant: "primary",
          cancelVariant: "secondary",
          okTitle: this.$t("modal.unsaved.yes").toString(),
          cancelTitle: this.$t("modal.unsaved.no").toString(),
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
          dialogClass: "modal-app",
        })
        .then((result) => {
          if (result) {
            this.tableRubrique = value;
            this.oldRubrique = value;
            this.$store.commit("changeHasBeenModified", false);
            this.handleChange();
          } else {
            this.selectedRubrique = this.oldRubrique;
          }
        });
    } else {
      this.tableCoefficientId = null;
      this.selectedCoefficientId = "";
      this.tableRubrique = value;
      this.oldRubrique = value;
      this.handleChange();
    }
  }

  private async handleBopModelChange(value: any) {
    const responseData = (await BopModelService.CanHaveExceptions(value.id))
      .data;
    const rubriqueIndex = this.rubriqueOptions.findIndex(
      (el) => el.value === RubriqueEnum.Exceptions
    );
    this.rubriqueOptions[
      rubriqueIndex
    ].disabled = !responseData.canHaveExceptions;
    if (
      !responseData.canHaveExceptions &&
      this.selectedRubrique === RubriqueEnum.Exceptions
    ) {
      this.selectedRubrique = null;
      this.tableRubrique = null;
    }
    if (this.$store.state.hasBeenModified) {
      this.$bvModal
        .msgBoxConfirm(this.$t("modal.unsaved.message").toString(), {
          title: this.$t("modal.unsaved.title").toString(),
          size: "lg",
          buttonSize: "lg",
          okVariant: "primary",
          cancelVariant: "secondary",
          okTitle: this.$t("modal.unsaved.yes").toString(),
          cancelTitle: this.$t("modal.unsaved.no").toString(),
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
          dialogClass: "modal-app",
        })
        .then((result) => {
          if (result) {
            this.tableBopModel = value;
            this.oldBopModel = value;
            this.$store.commit("changeHasBeenModified", false);
            this.handleChange();
          } else {
            this.selectedBopModel = this.oldBopModel;
          }
        });
    } else {
      this.tableCoefficientId = null;
      this.selectedCoefficientId = "";
      this.tableBopModel = value;
      this.oldBopModel = value;
      await this.handleChange();
    }

    if (this.selectedBopModel.key === BopModelEnum.CalorifugageAppareil) {
      this.removeRubric(RubriqueEnum.ComplementsCoutsSupp);
      this.addRubric({
        text: this.$t("bopModelSetup.ReferenceFourniture").toString(),
        value: RubriqueEnum.ReferenceFourniture,
        key: this.$i18n.locale,
        disabled: false,
      });
    } else if (this.selectedBopModel.key === BopModelEnum.TuyauterieMetalique) {
      this.removeRubric(RubriqueEnum.ReferenceFourniture);
      this.addRubric({
        text: this.$t("bopModelSetup.ComplementsCoutsSupp").toString(),
        value: RubriqueEnum.ComplementsCoutsSupp,
        key: this.$i18n.locale,
        disabled: false,
      });
    } else {
      this.removeRubric(RubriqueEnum.ReferenceFourniture);
      this.removeRubric(RubriqueEnum.ComplementsCoutsSupp);
    }
  }

  private addRubric(rubric: any) {
    const rubricImportIndex = this.rubriqueOptions.findIndex(
      (el) => el.value === SEPARATOR_KEY
    );
    this.rubriqueOptions.splice(rubricImportIndex, 0, rubric);
  }

  private removeRubric(rubricKey: string) {
    const rubricIndex = this.rubriqueOptions.findIndex(
      (el) => el.value === rubricKey
    );
    if (rubricIndex !== -1) {
      this.rubriqueOptions.splice(rubricIndex, 1);
    }
  }
  private loadCoefficientMetadata(value) {
    CoefficientService.getCoefficientById(value).then((response) => {
      this.coefficientMetadata = response.data;
      this.tableCoefficientId = value;
      this.oldCoefficientId = value;
      this.selectedCoefficientId = value;
    });
  }
  private handleCoefficientIdChange(value: any) {
    if (this.$store.state.hasBeenModified) {
      this.$bvModal
        .msgBoxConfirm(this.$t("modal.unsaved.message").toString(), {
          title: this.$t("modal.unsaved.title").toString(),
          size: "lg",
          buttonSize: "lg",
          okVariant: "primary",
          cancelVariant: "secondary",
          okTitle: this.$t("modal.unsaved.yes").toString(),
          cancelTitle: this.$t("modal.unsaved.no").toString(),
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
          dialogClass: "modal-app",
        })
        .then((result) => {
          if (result) {
            this.loadCoefficientMetadata(value);
            this.$store.commit("changeHasBeenModified", false);
            this.handleChange();
          } else {
            this.selectedCoefficientId = this.oldCoefficientId;
          }
        });
    } else {
      this.loadCoefficientMetadata(value);
      this.handleChange();
    }
  }

  private handleTypeTravauxChange(value: any) {
    if (this.$store.state.hasBeenModified) {
      this.$bvModal
        .msgBoxConfirm(this.$t("modal.unsaved.message").toString(), {
          title: this.$t("modal.unsaved.title").toString(),
          size: "lg",
          buttonSize: "lg",
          okVariant: "primary",
          cancelVariant: "secondary",
          okTitle: this.$t("modal.unsaved.yes").toString(),
          cancelTitle: this.$t("modal.unsaved.no").toString(),
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
          dialogClass: "modal-app",
        })
        .then((result) => {
          if (result) {
            this.tableTypeTravaux = value;
            this.oldTypeTravaux = value;
            this.$store.commit("changeHasBeenModified", false);
            this.handleChange();
          } else {
            this.selectedTypeTravaux = this.oldTypeTravaux;
          }
        });
    } else {
      this.tableTypeTravaux = value;
      this.oldTypeTravaux = value;
      this.handleChange();
    }
  }

  private async loadDropDowns() {
    this.rubriqueOptions = [];
    let responseBopModel = await BopModelService.getBopModels(
      this.$i18n.locale
    );
    if (responseBopModel.status === 200) {
      this.bopModels = responseBopModel.data;
    }

    this.rubriqueOptions = [
      {
        text: this.$t("bopModelSetup.OperationPrincipale").toString(),
        value: RubriqueEnum.OperationPrincipal,
        key: this.$i18n.locale,
      },
      {
        text: this.$t("bopModelSetup.OperationDiverse").toString(),
        value: RubriqueEnum.OperationDiverse,
        key: this.$i18n.locale,
      },
      {
        text: this.$t("bopModelSetup.CoefficientDimensions").toString(),
        value: RubriqueEnum.CoefficientDimensions,
        key: this.$i18n.locale,
      },
      {
        text: this.$t("bopModelSetup.Exceptions").toString(),
        value: RubriqueEnum.Exceptions,
        key: this.$i18n.locale,
        disabled: false,
      },
    ];
    // }
    this.typeTravaux = (
      await TypeTravauxService.listTypeTravaux(this.$i18n.locale)
    ).data;
  }

  @Watch("$i18n.locale")
  private onLocaleChange() {
    this.loadDropDowns();
  }
}
