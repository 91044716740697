import { Guid } from "guid-typescript";

export default class OperationGroup {
  public id: string;

  public number: number;
  public operations: Array<any>;
  public selectedGroupCoefficients: any;
  public operationFamilyId: any;
  public operationFamilyKey: string | null;
  public operationFamilyName: string | null;
  public canBeDeleted: boolean;
  public isNew: boolean;
  public isFourniture: boolean;
  constructor(
    operationFamilyId: string | null = null,
    operationFamilyName: string | null = null,
    operationFamilyKey: string | null = null
  ) {
    this.id = Guid.create().toString();
    this.number = 1;
    this.operations = [];
    this.selectedGroupCoefficients = {};
    this.operationFamilyId = operationFamilyId;
    this.operationFamilyName = operationFamilyName;
    this.operationFamilyKey = operationFamilyKey;
    this.canBeDeleted = false;
    this.isNew = true;
    this.isFourniture = false;
  }
}
