import { Vue } from "vue-property-decorator";
import { getVueInstance } from "@/main";

class APIProvider extends Vue {
  private isServerDown = false;
  getUrl() {
    return this.$api ? this.$api.defaults.baseURL : undefined;
  }

  async get(path: string, params: any = {}) {
    try {
      return await this.$api.get(path, params);
    } catch (error) {
      if (!this.isServerDown) {
        if (!error.response) {
          this.isServerDown = true;
          const response = await getVueInstance().$bvModal.msgBoxConfirm(
            "The server is down! Click on reload to try again",
            {
              title: "Error",
              size: "md",
              buttonSize: "md",
              okVariant: "primary",
              cancelVariant: "secondary",
              okTitle: "Reload",
              cancelTitle: "Cancel",
              footerClass: "p-2",
              hideHeaderClose: false,
              centered: true,
              dialogClass: "modal-app",
            }
          );
          if (response) {
            window.location.reload();
          }
        } else {
          if (error.response.status === 401) {
            const vueInstance = await getVueInstance();
            vueInstance.$store.commit("logout");
            if (
              !params.disable401Verification &&
              !vueInstance.$store.state.inRouter
            ) {
              window.location.reload();
            }
          }
        }
      }
      throw error;
    }
  }

  async post<T>(path: string, data: any = {}, params: any = {}) {
    try {
      return await this.$api.post(path, data, params);
    } catch (error) {
      if (error.response.status === 401 && !params.disable401Verification) {
        await getVueInstance().$router.push({ name: "Login" });
      }
      throw error;
    }
  }

  async put(path: string, data: any = {}, params: any = {}) {
    try {
      return await this.$api.put(path, data, params);
    } catch (error) {
      if (error.response.status === 401 && !params.disable401Verification) {
        await getVueInstance().$router.push({ name: "Login" });
      }
      throw error;
    }
  }
  async delete(path: string, config: any = {}) {
    try {
      return await this.$api.delete(path, config);
    } catch (error) {
      if (error.response.status === 401) {
        await getVueInstance().$router.push({ name: "Login" });
      }
      throw error;
    }
  }

  async patch(path: string, data: any = {}, params: any = {}) {
    try {
      return await this.$api.patch(path, data, params);
    } catch (error) {
      if (error.response.status === 401 && !params.disable401Verification) {
        await getVueInstance().$router.push({ name: "Login" });
      }
      throw error;
    }
  }
}

export default new APIProvider();
