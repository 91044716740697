




















































import { Component, Vue } from "vue-property-decorator";
import UserService from "@/services/UserService";
import Constants from "@/utils/Constants";

@Component({
  components: {},
})
export default class Accueil extends Vue {
  private token!: string;
  private mail!: string;
  private login!: string;
  private resetPassword: string = "";
  private resetPasswordConfirmation: string = "";
  private regexsPassword: Array<RegExp> = [
    /^.*[0-9].*$/,
    /^.*[#?!@$%^&*-].*$/,
    /^.*[A-Z].*$/,
    /^.*[a-z].*$/,
  ];

  public mounted() {
    this.token = this.$route.query.token.toString();
    this.mail = this.$route.query.mail.toString();
    this.login = this.$route.query.login.toString() || "WrongUserType";

    this.$store.commit("logout");

    if (!(this.token && this.mail)) {
      this.cancel();
    }
  }

  private checkPassword() {
    // - Le mot de passe doit avoir une longueur d’au moins 8 caractères
    if (this.resetPassword.length < 8) {
      this.$notify({
        group: "globalError",
        type: "error",
        title: this.$t("resetPassword.passwordLength.title").toString(),
        text: this.$t("resetPassword.passwordLength.description").toString(),
        duration: Constants.DEFAULT_WAIT_ERROR_NOTIFICATIONS,
      });
      return false;
    }

    // - Ne pas utiliser de mots contenus dans votre nom d’utilisateur
    if (this.resetPassword.includes(this.login)) {
      this.$notify({
        group: "globalError",
        type: "error",
        title: this.$t("resetPassword.loginInPassword.title").toString(),
        text: this.$t("resetPassword.loginInPassword.description").toString(),
        duration: Constants.DEFAULT_WAIT_ERROR_NOTIFICATIONS,
      });
      return false;
    }

    // - Utilisez au minimum trois types de caractères correspondants à ceux décrits ci-dessous : this.regexsPassword
    var regexMatch: number = 0;

    for (let regex of this.regexsPassword) {
      if (regex.test(this.resetPassword)) regexMatch++;
    }

    if (regexMatch < 3) {
      this.$notify({
        group: "globalError",
        type: "error",
        title: this.$t("resetPassword.dontVerifiesCondtions.title").toString(),
        text: this.$t(
          "resetPassword.dontVerifiesCondtions.description"
        ).toString(),
        duration: Constants.DEFAULT_WAIT_ERROR_NOTIFICATIONS,
      });
      return false;
    }

    return true;
  }

  public async reset() {
    if (this.resetPassword != this.resetPasswordConfirmation) {
      this.$notify({
        group: "globalError",
        type: "error",
        title: this.$t("resetPassword.passwordsDoesntMatch.title").toString(),
        text: this.$t(
          "resetPassword.passwordsDoesntMatch.description"
        ).toString(),
        duration: Constants.DEFAULT_WAIT_ERROR_NOTIFICATIONS,
      });
      return false;
    }

    if (!this.checkPassword()) return false;

    const data = {
      mail: this.mail,
      token: this.token,
      newPassword: this.resetPassword,
    };
    try {
      let res = await UserService.resetPassword(data);

      if (res.status == 200) {
        this.$notify({
          group: "global",
          type: "success",
          title: this.$t("resetPassword.success.title").toString(),
          text: this.$t("resetPassword.success.description").toString(),
        });
        this.cancel();
      }
    } catch (error) {
      if (error?.response?.data?.detail) {
        this.$notify({
          group: "globalError",
          type: "error",
          title: this.$t("resetPassword.error.title").toString(),
          duration: Constants.DEFAULT_WAIT_ERROR_NOTIFICATIONS,
          text: `resetPassword.error.${error.response.data.detail}`,
        });
      } else {
        this.$notify({
          group: "globalError",
          type: "error",
          title: this.$t("resetPassword.error.title").toString(),
          duration: Constants.DEFAULT_WAIT_ERROR_NOTIFICATIONS,
          text: "resetPassword.error.unknown",
        });
      }
    }
  }

  public cancel() {
    this.$router.push({ name: "Accueil" });
  }
}
