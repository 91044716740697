import Vue from "vue";
import Vuex, { Module } from "vuex";
import user from "@/store/user";
import Bop from "./models/Bop";
import VuexPersistence from "vuex-persist";
import Validation from "./models/Validation";
import Feb from "./models/Feb";
const pkg = require("@/../package.json");

const vuexSession = new VuexPersistence<any>({
  storage: window.sessionStorage,
});

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    showExportLoader: false,
    inRouter: false,
    isLoggedIn: false,
    usedSitesTypeTravaux: {},
    hasBeenModified: false,
    isFirstCheckBoxTableLoad: true,
    bop: new Bop(),
    statuses: [],
    validation: new Validation(),
    selectedBopModel: {},
    bopOperationOptions: {},
    selectedGlobalCoefficients: {},
    bopStatuses: null,
    operationFunctions: {},
    isRowClickTemporaryDisabled: false,
    APP_VERSION: pkg.version || 0,
    isTranslatinLoaded: false,
  },
  mutations: {
    changeShowExportLoader(state: any, value: boolean) {
      state.showExportLoader = value;
    },
    changeInRouter(state: any, value: boolean) {
      state.inRouter = value;
    },
    changeStatuses(state: any, value: Array<any>) {
      state.statuses = value;
    },
    changeIsTranslationLoaded(state: any, value) {
      state.isTranslatinLoaded = value;
    },
    changeIsRowClickTemporaryDisabled(state: any, isRowClickTemporaryDisabled) {
      state.isRowClickTemporaryDisabled = isRowClickTemporaryDisabled;
    },
    changeOperationFuctions(state: any, operationFunctions) {
      const operationId = operationFunctions.id;
      const functionUnitPoints = operationFunctions.functionUnitPoints;
      const functionOperationUnitPoints =
        operationFunctions.functionOperationUnitPoints;
      state.operationFunctions[operationId] = {
        functionUnitPoints,
        functionOperationUnitPoints,
      };
    },
    loadBopStatuses(state: any, bopStatuses: any) {
      state.bopStatuses = bopStatuses;
    },
    updateFeb(state: any, feb: Feb) {
      state.bop.feb = feb;
    },
    changeTotalOperationDiversePoints(state: any, total: number) {
      const bop: Bop = state.bop;

      bop.totalOperationDiversePoints = total;

      bop.totalPoints =
        bop.totalOperationPrincipalPoints +
        bop.totalOperationDiversePoints +
        bop.totalOperationSuppHeurePoints +
        bop.totalOperationSuppEpiPoints +
        bop.totalSuppHauteurPoints;
    },
    changetotalSuppHauteurPointsPoints(state: any, total: number) {
      const bop: Bop = state.bop;

      bop.totalSuppHauteurPoints = total;

      bop.totalPoints =
        bop.totalOperationPrincipalPoints +
        bop.totalOperationDiversePoints +
        bop.totalOperationSuppHeurePoints +
        bop.totalOperationSuppEpiPoints +
        bop.totalSuppHauteurPoints;
    },
    changeTotalOperationSuppHeurePoints(state: any, total: number) {
      const bop: Bop = state.bop;

      bop.totalOperationSuppHeurePoints = total;

      bop.totalPoints =
        bop.totalOperationPrincipalPoints +
        bop.totalOperationDiversePoints +
        bop.totalOperationSuppHeurePoints +
        bop.totalOperationSuppEpiPoints +
        bop.totalSuppHauteurPoints;
    },
    changeTotalOperationSuppEpiPoints(state: any, total: number) {
      const bop: Bop = state.bop;

      bop.totalOperationSuppEpiPoints = total;

      bop.totalPoints =
        bop.totalOperationPrincipalPoints +
        bop.totalOperationDiversePoints +
        bop.totalOperationSuppHeurePoints +
        bop.totalOperationSuppEpiPoints +
        bop.totalSuppHauteurPoints;
    },
    changeTotalOperationPrincipalPoints(state: any, total: number) {
      const bop: Bop = state.bop;

      bop.totalOperationPrincipalPoints = total;

      bop.totalPoints =
        bop.totalOperationPrincipalPoints +
        bop.totalOperationDiversePoints +
        bop.totalOperationSuppHeurePoints +
        bop.totalOperationSuppEpiPoints +
        bop.totalSuppHauteurPoints;
    },
    removeBopOperationPrincipalGroup(state: any, operationGroupIndex: number) {
      if (operationGroupIndex === -1) return;
      let operationGroups = (<Bop>state.bop).operationPrincipalGroups;

      // if (operationGroups[operationGroupIndex].isNew) {
      //   operationGroups.splice(operationGroupIndex, 1);
      // } else {
      operationGroups[operationGroupIndex].canBeDeleted = true;
      // }
    },
    removeBopOperationPrincipal(
      state: any,
      data: { operationGroupIndex: number; operationIndex: number }
    ) {
      const operationGroups = (<Bop>state.bop).operationPrincipalGroups;
      operationGroups[data.operationGroupIndex].operations.splice(
        data.operationIndex,
        1
      );
    },
    changeBopOperationPrincipal(
      state: any,
      data: { operationGroupIndex: number; operations: Array<any> }
    ) {
      const operationGroups = (<Bop>state.bop).operationPrincipalGroups;
      operationGroups[data.operationGroupIndex].operations = data.operations;
    },
    changeSelectedBopModel(state: any, selectedBopModel: any) {
      state.selectedBopModel = selectedBopModel;
    },
    changeSelectedGlobalCoefficients(
      state: any,
      selectedGlobalCoefficients: any
    ) {
      state.selectedGlobalCoefficients = selectedGlobalCoefficients;
    },
    changeBopOperationOptions(state: any, config: any) {
      if (!config) {
        state.bopOperationOptions = {};
        return;
      }
      const operationGroupId = config.operationGroupId;
      const bopOperationOptions = config.bopOperationOptions;
      state.bopOperationOptions[operationGroupId] = bopOperationOptions;
    },
    changeBop(state: any, bop: Bop) {
      state.bop = bop;
    },
    changeValidation(state: any, validation: Validation) {
      state.validation = validation;
    },
    changeHasBeenModified(state: any, bool: boolean) {
      state.hasBeenModified = bool;
    },
    changeIsFirstCheckBoxTableLoad(state: any, bool: boolean) {
      state.isFirstCheckBoxTableLoad = bool;
    },
    resetUsedSitesTypeTravaux(state: any) {
      state.usedSitesTypeTravaux = {};
    },
    changeUsedSitesTypeTravaux(state: any, data: any) {
      const siteId = data.siteId;
      const typeTravauxId = data.typeTravauxId;
      const operationCode = data.operationCode;
      if (!state.usedSitesTypeTravaux[operationCode])
        state.usedSitesTypeTravaux[operationCode] = {};
      state.usedSitesTypeTravaux[operationCode][`${siteId}_${typeTravauxId}`] =
        data.id;
    },
    removeTypeTravauxFromchangeUsedSitesTypeTravaux(state: any, data: any) {
      const siteId = data.siteId;
      const typeTravauxId = data.typeTravauxId;
      const operationCode = data.operationCode;
      if (
        state.usedSitesTypeTravaux[operationCode] &&
        state.usedSitesTypeTravaux[operationCode][`${siteId}_${typeTravauxId}`]
      ) {
        delete state.usedSitesTypeTravaux[operationCode][
          `${siteId}_${typeTravauxId}`
        ];
      }
    },
  },
  actions: {},
  modules: {
    userModule: user,
  },
  plugins: [vuexSession.plugin],
});
